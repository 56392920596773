// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-access-jsx": () => import("./../../../src/pages/access.jsx" /* webpackChunkName: "component---src-pages-access-jsx" */),
  "component---src-pages-contacts-jsx": () => import("./../../../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-equipment-jsx": () => import("./../../../src/pages/equipment.jsx" /* webpackChunkName: "component---src-pages-equipment-jsx" */),
  "component---src-pages-guidelines-jsx": () => import("./../../../src/pages/guidelines.jsx" /* webpackChunkName: "component---src-pages-guidelines-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-partners-jsx": () => import("./../../../src/pages/partners.jsx" /* webpackChunkName: "component---src-pages-partners-jsx" */),
  "component---src-pages-publications-jsx": () => import("./../../../src/pages/publications.jsx" /* webpackChunkName: "component---src-pages-publications-jsx" */),
  "component---src-pages-tutorials-jsx": () => import("./../../../src/pages/tutorials.jsx" /* webpackChunkName: "component---src-pages-tutorials-jsx" */)
}

